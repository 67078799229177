.loginPage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
}

.loginCard {   
    max-width: 500px;
    width: 100%;
    background: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .1);
    padding: 30px;
    margin: 20px;
    justify-content: center;
}

.loginCardTitle {
    position: relative;
    margin-bottom: 30px;
    text-shadow: 3px 3px 10px #00000099;
}

.loginCardBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.passwordVisibility {
    display: flex;
}

.btnVisibility {
    background-color: #fff;
    border: none;
    &:hover {
        cursor: pointer;
    }
    & img {
        width: 22px;
        height: 22px;
    }
}

.loginCardLable {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    width: 100%;
}

.loginCardInput {
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    font-size: 16px;
    height: 40px;
    padding: 5px 15px;
    &&:focus{
        outline: none;
        background: #cccccc14;
        box-shadow: 3px 3px 10px #818181;
      }
}


.loginCardButton{
    border-top: 1px solid #d5d5d5;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 40px;
    padding-top: 30px;
    text-align: right;
}

.loginCardLink {
    margin-right: 20px;
    color: #000;
    font-size: 15px;
    -webkit-text-decoration: underline;
    text-decoration: underline;
}

.loginCardBtnInp {  
    align-items: center;
    background: #1f1f21;
    border: none;
    border-radius: 7px;
    color: #fff;
    display: inline-flex;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
}
.loginCardBtnInp:hover {
    background-color: rgb(19, 182, 41);
    opacity: 0.6;
} 