.BtnForward {
    background-color: #f5f5f5; 
    border: 1px solid #ccc;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
      width: 45px;
      height: 45px;
  }
  
  .BtnForward img {
    width: 32px; 
    height: 32px; 
  }
  
  /* Цвет при наведении */
  .BtnForward:hover {
    background-color: rgb(255, 255, 0, 0.7); 
  }
  
  /* Цвет при нажатии */
  .BtnForward.active {
    background-color:  rgb(19, 182, 41, 0.9); 
  }