.ToggleCheckbox {
    opacity: 0; /* Скрываем input, но оставляем его в потоке */
    position: absolute; /* Убираем его с глаз */
}

.ToggleCheckbox + label {
    display: inline-block;
    width: 46px;
    height: 21px;
    background-color: #2196f3;
    border-radius: 17px;
    border: 1px solid #ccc;
    position: relative;
    cursor: pointer;
}

.ToggleCheckbox + label:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 26px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(255, 255, 0, 0.9);
    transition: transform 0.3s;
}
.ToggleCheckbox:checked + label {
    background-color: #ccc
}
.ToggleCheckbox:checked + label:after {
    transform: translateX(-27px);
} 
