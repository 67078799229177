.mainCard {
    display: flex;
    flex-wrap: nowrap;
    border-radius: 5px;
    justify-content: flex-start;
    margin-top: 45px;
}

.mainMup{
    width: calc(100%-400px);   
}

.mainMupMini {
    width: 100%;
    height: 650px;
}
.MupMiniHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #c3dcce;
    & h3{
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 10px;
    }
}

@media  (max-width: 800px) {
    .mainMup{
        display: none;
    }
}

@media  (min-width: 1050px) {
    .headerNavigatorMap button{
        display: none;
    }
}


.dmDetalsCard {
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-left: 5px; 
    margin-right: 5px; 
    width: 100%;
    max-width: 400px;  
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #fff;
}

.dmDetalMain {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #fff;
    max-height: 80vh;
    overflow-y: auto;
}

.dmDetalMain::-webkit-scrollbar {
    width: 10px;
  }
  
  .dmDetalMain::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    background-color: #f9f9fd;
    border-radius: 10px;
  }
  
.dmDetalMain::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(45deg,rgb(82, 182, 85),  rgb(182, 221, 189));
  }

.detalsHeader {
    border-bottom: 1px solid #ccc;
    width: 100%;
    max-width: 400px;
    padding: 2px;
    margin: 3px 2px;
    background: #fff;
    position: -webkit-sticky; 
    position: sticky;
}

.detalsHeaderName {
    margin-top: 3px;
    margin-left: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    font-family: sans-serif;
    font-style: italic;
    & h2{
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

.dmConfigCardFooter {
    width: 100%;
    border-top: 1px solid #ccc;
    font-size: 16px;
    font-style: italic;
    padding-top: 5px;
    padding-bottom: 5px;
    & span {
        margin: 0px 20px;
    }
}

.headerNavigator {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & button{
        background-color: #fff;
        border: none;
        margin-left: 5px;
        cursor: pointer;
    }
}

.headerNavigatorSelect{
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 7px;
    & select { 
        padding: 2px;
        font-size: 20px;
        border: none;
        cursor: pointer;
    }
}

.headerNavigatorBack button{
    border: none;
    margin-right: 5px;
    cursor: pointer;
}
 
.headerNavigatorBuckMap button {
    background-color: #c3dcce;
    border: none;
    margin-right: 5px;
    cursor: pointer;
    & :hover {
        background-color: rgb(135, 237, 149, 0.9); 
    }  
    & :active {
        background-color: rgb(135, 237, 149, 0.9); 
    }
}

.dmDetalsPosition {
    border: 1px solid #ccc;
    border-radius: 10px; 
    margin: 10px;
    padding: 15px;
    width: 200px;
    height: 200px;
}

.positionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between ;
    padding-bottom: 5px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ccc;
    & h5{
        margin-left: 5px;
    }
}

.PositionCircle {
    margin: auto;
    padding: auto;
    align-items: center;
    justify-content: center;
    display: flex;
}






