.dmNetworkCard {
    border: none;
    width: 100%; 
    align-items: center;
    padding: 10px;
    margin: 3px 2px;
    max-width: 400px; 
    background: #FFF;    

}
.dmNetworkCardHeader {
    border-bottom: 1px solid #ccc;
    align-items: center;
    padding-bottom: 5px;
    margin-bottom: 20px;
    
}
.dmNetworkCard h4 {
    margin: 5px;
}

.dmNetworkCard span {
 margin: 15px;
}

.dmNetworkOperator{
  margin-bottom: 5px;
}

.dmBalanse {
    margin-bottom: 5px;
}

.dmNamberFone {
    margin-bottom: 5px;
}