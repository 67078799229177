.footerNav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(195, 220, 206);
    border-top: 1px solid #5c5c5c;
    height: 40px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.navLink {
    font-family: Inter, sans-serif;
    font-size: 16px;
    color: black;
    align-items: center;
    border: none;
    border-radius: 25%;
    cursor: pointer;
    transition: .3;
}

.navLink:hover{
   color:rgb(72, 30, 226);
}