
dialog {
    background-color: #fff;
    width: 80%;
    max-width: 400px;
    
    /* max-height: 300px; */
    max-height: 60%;
    padding: 5px 10px 10px;
    border-radius: 10px; 
    /* position: absolute; */
    left: 50%;
    /* top: 50%; */
    top: 30%;
    transform: translate(-50%, -50%);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}
