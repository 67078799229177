.BarrierCard {
/* border: 1px solid #ccc;
border-radius: 10px;
margin: auto;
width: 100%;
display: flex;
flex-wrap: wrap;
flex-direction: column;
background: #fff;
max-width: 400px; */


    /* border: 1px solid #ccc; */
    /* border-radius: 10px; */
    margin: auto;
    /* max-width: 400px; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    align-items: center;
    justify-content: center;
}

.BarrierUse {
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    margin: 15px 2px;
    & label {
      margin-right: 15px;
  }
}

.barrierOff {
    color: red;
    display: flex;
    align-items: center;
    justify-content: space-around; 
    margin: 21px 20px;
    & h2 {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
.warningInfo {
    display: flex;
    align-items: center;
    justify-content: space-around; 
    margin: 10px 20px;
}

.angleInfo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    & p { 
        margin-right: 10px;
    }
    & h3 {
        font-style: italic;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    & h4 {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.MovDirection {
    display: flex;
    flex-direction: column;
    margin: 10px 20px 10px;
    padding: 5px;
    border-bottom: 1px solid #ccc;
    & h3{
       margin-left: auto;
       margin-right: auto;
       margin-top: 5px;
       margin-bottom: 5px;
    }
    
}

.BarrierAngle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ChoiceAction {
    display: flex;
    flex-direction: row;
    margin: 5px 20px 10px 40px;  
    align-items: center;
    justify-content: space-around;
    
}

.VarAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    & img {
        height: 32px;
        width: 32px;
    }
}

.barriOn-enter {
    opacity: 0;
    transform: translateX(-200px);
  }
  .barriOn-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }
  .barriOn-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .barriOn-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 500ms, transform 500ms;
  }
  
  .barriOff-enter {
    opacity: 0;
    transform: translateX(200px);
  }
  .barriOff-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }
  .barriOff-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .barriOff-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 500ms, transform 500ms;
  }
