.Offline {
    margin-left: 5px;
    margin-right: 10px;
    border: 1px solid #de6a60;
    color: red;
    border-radius: 15px;
    background: #fdf5f3;   
   
}
.Offline {
    transition: border 0.3s, color 0.3s; /* Анимация */
}


.Online {
    margin-left: 5px;
    margin-right: 10px;
    border: 1px solid #b1f5d0;
    color: #00591F;
    border-radius: 15px;
    background: #ebfbee;
}

.Online {
    transition: border 0.3s, color 0.3s;/* Анимация */
}

.linkText{
    font-size: 12px;
    display: flex;
    justify-content: center;
    margin: 0px 5px;
}