.BtnStop {
    background-color: #e26969cc; 
    border: 1px solid #ccc;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
      width: 45px;
      height: 45px;
  }
  
  .BtnStop img {
    width: 32px; 
    height: 32px; 
  }
  
  /* Цвет при наведении */
  .BtnStop:hover {
    background-color: rgba(233, 95, 87, 0.9); 
  }
  
  /* Цвет при нажатии */
  .BtnStop:active {
    background-color: rgb(182, 25, 17, 0.9); 
  }
