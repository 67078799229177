.navbar {
    max-height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #5c5c5c;
    background-color: rgb(195, 220, 206);
    position: fixed; /* Фиксируем шапку */
    top: 0; /* Выставляем шапку вверху страницы */
    left: 0; /* Выставляем шапку в левом углу страницы */
    width: 100%; /* Шапка занимает всю ширину страницы */
    z-index: 1000; /* Увеличиваем индекс Z, чтобы шапка былаVisible над остальным содержимым */
}
.navbarLogo{
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 10px;
    padding: 5px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    text-decoration: none;
    color: black;
}
.navbarLogoImg{
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.navbarBlock {
    display: flex;
    margin-right: 5px;
    align-items: center;
}

.navbarInfo {
    margin-left: 7px;
}
.navbarInfoRole {
 font-size: 14px;
 margin-top: 4px;
 margin-bottom: 4px;
}

.navbarInfoRole h4{
 margin-top: 4px;
 margin-bottom: 4px;
}

.navbarInfoUsername {
font-family: 13px;
color: #5c5c5c;
}
.navbarOut {
    margin-left: 10px;
}

.navbarBtnOut {   
    margin-top: 3px;
    margin-bottom: 3px;   
    align-items: center;
    background: #e96c60;
    border: none;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 0;
    transition: .3s;
    width: 40px;
    cursor: pointer;
}

.navbarBtnOut:hover{
    background-color: #e93424;
}

.navbarLogout {
    height: 26px;
    width: 26px;
    margin-bottom: 2px;
}

.navbarBtnLK {
    margin-top: 3px;
    margin-bottom: 3px;   
    align-items: center;
    background: #98e855;
    border: none;
    border-radius: 20%;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 0;
    transition: .3s;
    width: 40px;
    cursor: pointer;
    margin-left: 10px;
}

.navbarBtnLK:hover{
    background-color: #1de217;
}

.navbarLkIn {
    font-family: Inter, sans-serif;
    font-size: 16px;
    text-decoration: none;
    color: black;
    align-items: center;
    background: #98e855;
    border: none;
    border-radius: 25%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-bottom: 3px;
    margin-left: 10px;
    margin-top: 3px;
    padding: 0;
    transition: .3s;
    width: 55px;
}
.navbarLkIn:hover{
    background-color: #1de217;
}
