.button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0,3s;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
    background: #6ce609;
}

.button:hover {
    opacity: 0.8;
    background: #09e6c9;
}

.clicked {
    background: #e73407;
    color: #fff
}