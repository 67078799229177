
.dmDetalsCommand {
    padding: 10px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    background: #fff;
    margin: 3px 2px;
}

.commandHeader {
    align-items: center;
    padding-bottom: 5px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ccc;
}

.commandBlock{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.commandRegulation{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: auto;
    margin-top: 20px;
}

.commandBlockSwitch {
    align-items: center;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    height: 55px;
    justify-content: center;
    opacity: 1;
    width: 55px;
    & img{
        margin-bottom: 3px;
    }
}
