.RestorePage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
}

.RestoreCard {
    max-width: 500px;
    width: 100%;
    background: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .1);
    padding: 30px;
    margin: 20px;
    justify-content: center;
}

.RestoreCardTitle {
    margin-bottom: 30px;
    padding-right: 50px;
    position: relative;
}

.RestoreCardUser {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    width: 100%;
}

.RestoreCardPassword {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.RestoreCardLable {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    width: 100%;
}

.RestoreCardInput {
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    font-size: 12px;
    height: 40px;
    padding: 5px 15px;
}

.RestoreCardLable {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    width: 100%;
}


.RestoreCardButton {
    border-top: 1px solid #d5d5d5;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 40px;
    padding-top: 30px;
    text-align: right;
}

.RestoreCardLink {
    margin-right: 20px;
    color: #000;
    font-size: 15px;
    -webkit-text-decoration: underline;
    text-decoration: underline;
}

.RestoreCardBtnInp {  
    align-items: center;
    background: #1f1f21;
    border: none;
    border-radius: 7px;
    color: #fff;
    display: inline-flex;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
}

.RestoreCardBtnInp:hover {
    background-color: rgb(19, 182, 41);
    opacity: 0.6;
} 

.passwordVisibility {
    display: flex;
}

.btnVisibility {
    background-color: #fff;
    border: none;
    &:hover {
        cursor: pointer;
    }
    & img {
        width: 22px;
        height: 22px;
    }
}