.dmConfigCard {
    /* border: 1px solid #ccc; */
    /* border-radius: 10px; */
    margin: auto;
    /* max-width: 400px; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #fff;;
}

.cardDm {
    width: inherit;
    padding: 10px;
    & h3 {
        margin-top: 0px;
        margin-bottom: 5px;
    }
}

.DmSelect {
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-style: italic;
    margin-right: 15px;
    &:hover{
        background-color:rgb(168, 241, 235);
        opacity: 0.6;
    }
}

.PassportSettings button {
    background: #FFF;
}

.ParamInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 2px;
    & button {
        background: #FFF;
    }
} 

.buttonInput {    
    text-decoration: underline;
    font-size: 16px;
    font-style: italic;
}

.modalHeader {
    display:  flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    & svg:hover{
        cursor: pointer;
    }
}

.modalInfo {
    margin-left: 10px;
    & h4 {
    margin-bottom: 2px;
    }
}
.labelInfo{
    margin-bottom: 15px;
}

.labelInfoQuet {
    margin-top: 10px;
    color: #b1afaf;
}
  .contentControl {
    display:  flex;
    flex-wrap: wrap;
    justify-content: center; 
    flex-direction: column;
    align-items: center;
    
  }

  .input {
    height: 30px;  
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modalButton {
    justify-items: center;
}

.GeoLink {
    align-items: center;
    margin-bottom: 5px;
    margin-left: 10px;
    padding-top: 5px;
    & img {
        width: 22px;
    height: 22px;
    cursor: pointer;

    }
}

.GeoLink label {
    margin-right: 20px;
}

.GeoLinkBtn {
    display: inline-flex;   
    justify-content: center;  
    align-items: center; 
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 30px;
    height: 30px;
}

.checkboxPress {
    margin-right: 15px; 
}
.selectSms{
    border: none;
}



