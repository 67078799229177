.rezInInfo {
    background-color:#a1edab8a; 
    border: 1px solid #b9f1b4;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
      width: 24px;
      height: 24px;

}
