.angle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
}

.angleImg {
    margin: 3px;
    width: 12px;
    height: 12px;
}