.DmList{
    max-width: 1250px;
    justify-content: center; 
    margin-left: auto;
    margin-right: auto; 
    margin-top: 48px; /* смещаем ниже Navbur   */
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.DmListHeader {
    position: -webkit-sticky; /* Для Safari */
    position: fixed;
    top: 0;  /* позиция от верхнего края экрана */
    margin-top: 45px;
    width: 98vw;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    align-items: center;
    max-width: 1250px;
    border: 1px solid #ccc;
    border-radius: 10px;

    & h2 {
        margin: 2px;
    }

    & span {
        color: #818181;
        display: inline-block;
        margin-left: 10px;
    }
}

.DmListName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & h2 {
        margin: 2px;
    }
}

.DmlistBtn {
    padding: 5px 10px;
    color: #FFF;
    background: #1f1f21;
    border: none;
    border-radius: 7px;
    display: inline-flex;
    font-size: 15px;
    cursor: pointer;

    :hover {
        background-color: rgb(19, 182, 41);
        opacity: 0.6;
    }  
}

.DmListQuest{
    display: flex;
}
.DmListQuestImgMag{
    width: 30px;
    height: 30px;
    margin-left: 10px;
    max-width: 300px;
}
.DmListInput{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    width: 100%;
    margin-bottom: 5px;
  }

  .DmListMain{
     max-width: 1240px;
     width: 98vw;
     max-height: calc(100%-175px);
     overflow-y: auto;
     position: fixed;
     top: 0;

     margin-top: 122px;
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-start; 
     padding: 5px;
     border: 1px solid #ccc;
     border-radius: 15px;
  }

  .DmListMain::-webkit-scrollbar {
    width: 10px;
  }
  
  .DmListMain::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    background-color: #f9f9fd;
    border-radius: 10px;
  }
  
.DmListMain::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(45deg,rgb(82, 182, 85),  rgb(182, 221, 189));
  }