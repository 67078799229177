.signalValue {
    display: flex;
    align-items: flex-end;
    width: 26px;
    height: 18px;   
}

.bar {
    width: 4px;
    margin-right: 3px;
    transition: background-color 0.3s, height 0.3s; /* Анимация */
  }
  
  .bar.offline {
    background-color: #aba0a6cc;
  }

  .bar.degraded {
    background-color: rgb(148, 100, 236); /* Цвет для деградированного состояния */
  }
  
  /* Уровни сигнала */
.bar.level-1 {
    background-color: red;
  }
  
  .bar.level-2 {
    background-color: #f0b410; /* Желтый */
  }
  
  .bar.level-3 {
    background-color: #f0b410; /* Желтый */
  }
  
  .bar.level-4 {
    background-color: #0ca32a; /* Зеленый */
  }
  
  /* Высоты столбцов для каждого уровня */
  .bar.level-1 {
    height: 25%;
  }
  
  .bar.level-2 {
    height: 50%;
  }
  
  .bar.level-3 {
    height: 75%;
  }
  
  .bar.level-4 {
    height: 100%;
  }