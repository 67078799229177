.mainContent {
      margin: 0; /* Убираем отступы */
      height: 100vh; /* Высота экрана */
      background-image: url('../../img/4.jpg'); /* Путь к изображению */
      background-size: cover; /* Масштабируем, чтобы покрывало весь экран */
      background-repeat: no-repeat; /* Без повторения */
      background-position: center; /* Центруем изображение */
}
.headerContent {
    margin-top: 45px;
    display: flex;
    justify-content: flex-end;
    & h1 {
        margin-right: 20px;
        font-family: sans-serif;
        font-style: italic;
        padding-top: 80px;
        font-size: 55px;
        width: 475px;
    }
}