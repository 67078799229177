.gunInfo {
    background-color:#a1deed8a; 
    border: 1px solid #b4d1f1;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
      width: 24px;
      height: 24px;

}

.gunInfo img {
    width: 22px; 
    height: 22px; 
  }