.GunSectorCard {
    /* border: 1px solid #ccc;
    border-radius: 10px;
    margin: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background: #fff;
    max-width: 400px; */

        /* border: 1px solid #ccc; */
    /* border-radius: 10px; */
    margin: auto;
    /* max-width: 400px; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    align-items: center;
    justify-content: center;
}

.GunSectorUse {
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    margin: 15px 2px;
    & label {
        margin-right: 15px;
    }
}
.sektorsHeader {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
    justify-content:center;
   
}

.gunUseOff {
    color: red;
    display: flex;
    align-items: center;
    justify-content: space-around; 
    margin: 21px 20px;
    & h2 {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.warningGunInfo {
    display: flex;
    align-items: center;
    justify-content: space-around; 
    margin: 10px 20px;
}

.angleGunInfo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    & p { 
        margin-right: 10px;
    }
    & h3 {
        font-style: italic;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    & h4 {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.sectorBeginEnd {
    display: flex;
    align-items: center;
    margin: 5px 2px;
    border-bottom: 1px solid #ccc;
    & h5 {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 14px;
    }
    & p {
        margin: 0px 10px;
        margin-right: 40px;
    }
    & span {
        margin-right:  40px;
    }
}

.sectorsBody {
    display: flex;
    align-items: center;
    justify-content:center;
}

.sector {
    border: 1px solid #ccc;
    border-radius: 7px;
    display: flex;
    align-items: center;
    margin: 5px 2px;
    padding: 0px 5px;
    min-width: 80px;
    & p {
        margin-left: 15px;
        margin-right:  40px;
       
    }
    & span {
        font-size: 16px;
    }
     & td {
        width: 80px;
     }
     & button {
        background: #FFF;
     }
}
.btnSector {
    font-style: italic;
    text-decoration: underline;
}

.GunSectorCardFooter {
    border-top: 1px solid #ccc;
    margin: 5px 20px;
    font-size: 16px;
    font-style: italic;
}

.gunOn-enter {
    opacity: 0;
    transform: translateX(-200px);
  }
  .gunOn-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }
  .gunOn-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .gunOn-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 500ms, transform 500ms;
  }
  
  .gunOff-enter {
    opacity: 0;
    transform: translateX(200px);
  }
  .gunOff-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }
  .gunOff-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .gunOff-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 500ms, transform 500ms;
  }