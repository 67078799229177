.imeiCard {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border: 2px solid #12c54e; 
    border-radius: 10px;
    margin: 5px; 
}

.imeiCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.cardHeaderName {
    border: 1px solid #1dc20e;
    border-radius: 20px;
    padding: 2px 10px;
    background-color: #b7ebb2;
    &:hover {
        border: 1px solid #12b9c5;
        background-color:#12b9c573;
        cursor: pointer;
        transition: .3s;
    }
}

.imeiCardBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imeiInfoTitle {
    font-size: 14px;
}

.imeiCardBodyInfo span {
    font-size: 12px;
    font-style: italic;
}

.imeiCardBodyBtn {
    display: flex;
    flex-direction: row;
}


.imeiBtn {   
    background-color: #f5f5f5; 
    border: 1px solid #ccc;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    width: 35px;
    height: 35px;
    margin: 5px;
}

.imeiBtn img {
    width: 28px; 
    height: 28px; 
}

.imeiBtn:hover{
    background-color: rgba(224, 224, 143, 0.7); 
}

.relocationModalHeader {
    display: flex;
    align-items: center;
    & h3 {
        margin: 2px;
    }
    & h4 { 
        margin: 2px;
        padding: 5px;
        margin-left: 5px; 
    }
}

.relocationInput {
    height: 20px; 
    width: 100%;
    margin-top: 0px;
    margin-bottom: 2px;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    padding: 5px 15px;
}

.relocationSelectMain {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    & h3 {
        color: #b1afaf;
         margin-left: auto;
        margin-right: auto;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    & h4 {
        margin: 0px auto 5px;
    }
   
}

.relocationSelectUser {
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    padding: 5px 5px;
    max-height: 250px;
    overflow: auto;
    display: flex;
    flex-direction: column;
      & p {
        margin: 2px;
        font-size: 12px;
    }
    
}

.selectUserOn {
    padding: 4px 10px;
    border: 1px solid #1dc20e;
    border-radius: 10px;
    background-color:#b7ebb2;
    cursor: pointer;
}

.selectUserOff {
    padding: 4px 10px;
    border: 1px solid #12b9c5;
    border-radius: 10px;
    background-color:#cff2f4;
    cursor: pointer;
}

.relocationConfirm {
    margin: 2px;
    margin-left: auto;
    margin-right: auto;
}

.relocationUserSearch {
    margin-top: 5px;
    display: flex;
    align-items: center;
    & img {
        width: 24px;
        height: 24px;
        margin-right: 5px;
    }
}


