.userCard {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border: 2px solid #12b9c5; 
    border-radius: 10px;
    margin: 5px; 
    & p {
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

.userCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & h3 {
        margin-top: 2px;
        margin-bottom: 2px;
    }
    & span {
    color: #818181;
    display: inline-block;
    margin-left: 10px;
    }
}

.userCardHeaderName {
    margin-left: 20px;
    align-items: center;
    display: flex;
}

.userCardBodyInfo {
    margin-left: 5px;
    & h4 {
        margin-top: 2px;
        margin-bottom: 2px;
    }
    & span {
    color: #818181;
    display: inline-block;
    margin-left: 10px;
    }
}
.userCardBodyEmail {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.userCardBodyDms {
    display: flex;
    flex-wrap: wrap;
}

.userCardNotDms {
    margin-left: 25px;
}

.dmImeiName {
margin: 5px;
padding: 2px 10px;
border: 1px solid #12b9c5;
border-radius: 20px;
background-color:#cff2f4;
color: black;
font-style: italic;
    &:hover{
        border: 1px solid #1dc20e;
        background-color:#b7ebb2;
        cursor: pointer;
        transition: .3s;
    }
}

.userCardHeaderBtn {
    display: flex;
    align-items: center;
    & span {
        margin-left: 10px;
        border: 1px solid red;
        border-radius: 18px;
        padding: 2px 10px;
        color: red;
        background-color: #ffafb5;
    }
}

.userBtn {   
    background-color: #f5f5f5; 
    border: 1px solid #ccc;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    width: 35px;
    height: 35px;
    margin: 5px;
}

.userBtn img {
    width: 28px; 
    height: 28px; 
}

.userBtn:hover{
    background-color: rgba(224, 224, 143, 0.7); 
}

.userCardInfo{
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    & p {
        margin-top: 3px;
        margin-bottom: 3px;
        margin-left: 15px;
        font-style: italic;
        font-weight: 500;
        color: #116fe9;
    }
}
.userCardQuest {
    color: #b1afaf;
    margin-left: 30px;
}

.userCardInfoInput {
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px 15px;
    &&:focus{
        outline: none;
        background: #cccccc14;
        box-shadow: 2px 2px 4px #818181;
      }
}

.userLabelInfo {
    margin-top: 0px;
    margin-bottom: 2px;
    & h4 {
        margin-top: 3px;
        margin-bottom: 3px;
        margin-left: 25px;
        color: #b1afaf;
    }
}