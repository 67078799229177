.CalcTime {
    background-color: #FFF;
    border: 1px solid #ccc;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    height: 65px;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
    width: 80px;
    cursor: pointer;
}

.CalcTime img {
    height: 24px;
    width: 24px;
}

.CalcBtn :hover {
    background-color: #e4e4e4;
    transition: .2s;
}