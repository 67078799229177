.RegisterPage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
}

.RegisterCard {
    
    max-width: 500px;
    width: 100%;
    background: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .1);
    padding: 30px;
    margin: 20px;
    justify-content: center;
}

.RegisterCardBody {
    max-height: 60vh;
    overflow-y: auto;
}

.RegisterCardBody::-webkit-scrollbar {
    width: 10px;
  }
  
  .RegisterCardBody::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    background-color: #f9f9fd;
    border-radius: 10px;
  }
  
.RegisterCardBody::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(45deg,rgb(82, 182, 85),  rgb(182, 221, 189));
  }

.RegisterCardTitle {
    margin-bottom: 30px;
    padding-right: 50px;
    position: relative;
}

.RegisterCardBlock {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 20px;
}

.RegisterCardLable {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    width: 100%;
}

.RegisterCardInput {
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    font-size: 12px;
    height: 40px;
    padding: 5px 15px;
}

.RegisterCardLable {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    width: 100%;
}


.RegisterCardButton {
    border-top: 1px solid #d5d5d5;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 25px;
    padding-top: 30px;
    text-align: right;
}

.RegisterCardLink {
    margin-right: 20px;
    color: #000;
    font-size: 15px;
    -webkit-text-decoration: underline;
    text-decoration: underline;
}

.RegisterCardBtnInp {  
    align-items: center;
    background: #1f1f21;
    border: none;
    border-radius: 7px;
    color: #fff;
    display: inline-flex;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
}

.RegisterCardBtnInp:hover {
    background-color: rgb(19, 182, 41);
    opacity: 0.6;
} 

.passwordVisibility {
    display: flex;
}

.btnVisibility {
    background-color: #fff;
    border: none;
    &:hover {
        cursor: pointer;
    }
    & img {
        width: 22px;
        height: 22px;
    }
}