.dmCardInfo {
    width: 100%; 
    align-items: center;
    padding: 10px;
    margin: 3px 2px;
    max-width: 400px; 
    background: #FFF;     
}

.cardInfoHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
}

.cardInfoBody{
    display: flex;
    justify-content: space-around;
    margin: 4px; 
}

.InfoBodyBlock {
    margin-top: 10px;
    min-width: 50%;
}

.InfoParam{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
}