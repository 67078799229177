
.cardPivot {
    border: 1px solid #ededed;
    border-radius: 10px;
    width: 100%; 
    align-items: center;
    padding: 2px;
    margin: 3px 2px;
    max-width: 400px;      
}

.cardPivot:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: 2px 2px 6px #818181;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
}

.cardHeaderInfo {
    display: flex;
    align-items: center
}

.cardHeaderInfoP {
    margin-left: 3px;
    margin-right: 3px;
}
.cardHeaderInfoIn {
    margin-left: 3px;
    margin-right: 3px;
    scale: 0.7;
}

.cardHeaderLink {
    display: flex;
}

.cardBody{
    display: flex;
    justify-content: space-around;
    margin: 4px; 
}

.cardBodyCircle {
    margin: 5px;   
}

.circle {
    width: 60px;
    height: 60px;
}

.cardInfo {
    min-width: 70%;
    margin: 5px 2px;
}

.cardInfoName {
    margin: 3px 7px;
}

.BodyImg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.cardBodyParam {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    font-size: 14px;
       
}
.cardBodyPercent {
    display: flex;
    align-items: center;
}
.cardBodyMeasuring {
    display: flex;
    align-items: center;
}
.cardBodyTime {
    display: flex;
    align-items: center;
}

.cardFooter {
    color: #000;
    font-size: 12px;
    font-style: italic;
    margin-left: 7px;
}




